import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import {Link, NavLink, useParams} from "react-router-dom";



function Ads(){
    const [items, setItems] = React.useState(null);
    const [columns, setColumns] = React.useState(null);

    React.useEffect(() => {
        async function getColumns() {
            const response = await axios.post("https://api.mr-flip.ru/api/v1/data/columns");
            console.log(response.data.columnNames)
            setColumns(response.data.columnNames);
        }

        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/get/ads");
            console.log(response.data.data)
            setItems(response.data.data);
        }

        getColumns();
        getData();
    }, []);

    if(!columns || !items) return <Loader />;

    return (
        <>
            <h1>Оффер #234</h1>

        </>
    );
}

export default Ads;
