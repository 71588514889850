import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import BootstrapTable from "react-bootstrap-table-next";

const columnsTable = [
    {
        dataField: 'id',
        text: 'ID',
        sort: true
    }, {
        dataField: 'link',
        text: 'Ссылка',
        sort: true
    }, {
        dataField: 'counter',
        text: 'Приведенных',
        sort: true
    }
];

function Charges(){
    const [charges_1, setCharges_1] = React.useState(null);
    const [charges_2, setCharges_2] = React.useState(null);
    const [charges_3, setCharges_3] = React.useState(null);
    const [charges_4, setCharges_4] = React.useState(null);
    const [charges_5, setCharges_5] = React.useState(null);
    const [charges_6, setCharges_6] = React.useState(null);
    const [charges_7, setCharges_7] = React.useState(null);
    const [charges_8, setCharges_8] = React.useState(null);
    const [savedMessage, setSavedMessage] = React.useState(null);
    const [sendMessage, setSendMessage] = React.useState(null);
    const [users, setUsers] = React.useState(null);
    const [checkedUsers, setCheckedUsers] = React.useState([]);
    const [setTextAreaText, textAreaText] = React.useState("Произвольный текст для отправки");
    const [text, setText] = React.useState("Текст начисления");
    const [priceCharges, setPriceCharges] = React.useState(0);

    const handleChange = (event) => {
        setText(event.target.value);
    };
    const handleChangePriceCharges = (event) => {
        setPriceCharges(event.target.value);
    };

    React.useEffect(() => {
        async function getLevels() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/charges/get");
            console.log(response.data.charges);
            setCharges_1(response.data.charges[0]['percents']);
            setCharges_2(response.data.charges[1]['percents']);
            setCharges_3(response.data.charges[2]['percents']);
            setCharges_4(response.data.charges[3]['percents']);
            setCharges_5(response.data.charges[4]['percents']);
            setCharges_6(response.data.charges[5]['percents']);
            setCharges_7(response.data.charges[6]['percents']);
            setCharges_8(response.data.charges[7]['percents']);
        }
        async function getUsers() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/charges/get/users");
            console.log(response.data.users);
            setUsers(response.data.users);
        }
        getLevels();
        getUsers();
    }, []);

    async function change(){
        let data = {
            "level_1":charges_1,
            "level_2":charges_2,
            "level_3":charges_3,
            "level_4":charges_4,
            "level_5":charges_5,
            "level_6":charges_6,
            "level_7":charges_7,
            "level_8":charges_8
        }
        const response = await axios.post("https://api.mr-flip.ru/api/dashboard/charges/set", data);
        console.log(response.data);

        if(response.data.message === "ok"){
            setSavedMessage("Данные сохранены в базу")

            setTimeout(() => {
                setSavedMessage("")
            }, 2500);
        }
    }

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setCheckedUsers([...checkedUsers, id]);
        } else {
            setCheckedUsers(checkedUsers.filter(userId => id !== userId));
        }
    };

    async function handleButtonClick() {
        const checkedData = users.filter(user => checkedUsers.includes(user.id));
        console.log(checkedData);
        console.log(text);

        let data = {
            users:checkedData,
            price:priceCharges,
            message:text
        }

        const response = await axios.post("https://api.mr-flip.ru/api/dashboard/charges/send/money", data);

        setSendMessage("Отправили рефералам по уровням выше")
        setTimeout(() => {
            setSendMessage("")
        }, 3500);
        console.log(response.data);
    }

    if(!users || charges_1 === null) return <Loader/>;

    return (
        <>
            <div className="content-row charges-page">
                <div className="left-side">
                    <h1>Распределить %</h1>
                    <div className="object-table charges-table">
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 1
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_1} onChange={e => setCharges_1(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 2
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_2} onChange={e => setCharges_2(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 3
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_3} onChange={e => setCharges_3(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 4
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_4} onChange={e => setCharges_4(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 5
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_5} onChange={e => setCharges_5(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 6
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_6} onChange={e => setCharges_6(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 7
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_7} onChange={e => setCharges_7(e.target.value)}/>%
                            </div>
                        </div>
                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                Level 8
                            </div>
                            <div className="object-table-row-cell">
                                <input type="number" value={charges_8} onChange={e => setCharges_8(e.target.value)}/>%
                            </div>
                        </div>

                        <div className="object-table-row">
                            <div className="object-table-row-cell">
                                <button onClick={change}>Сохранить</button>
                            </div>
                            <div className="object-table-row-cell">
                                Суммарно: {(parseFloat(charges_1) + parseFloat(charges_2) + parseFloat(charges_3) + parseFloat(charges_4) + parseFloat(charges_5) + parseFloat(charges_6) + parseFloat(charges_7) + parseFloat(charges_8)).toFixed(2)}%
                            </div>
                        </div>
                    </div>
                    <span className="savedText">{savedMessage}</span>
                </div>
                <div className="right-side">
                    <h1>Начислить</h1>
                    <div className="row">
                        Предложили объект <input type="checkbox" />
                    </div>
                    <div className="row">
                        Привели людей от <input type="number"/> до <input type="number"/>
                    </div>
                    <div className="row">
                        Всего рефераллов от <input type="number"/> до <input type="number"/>
                    </div>
                    <div className="row">
                        Рефераллы предложили объектов от <input type="number"/> до <input type="number"/>
                    </div>

                    <div className="row">
                        <div>
                            <button className="charges-button-send" onClick={handleButtonClick}>Начислить рефераллам</button>
                            <span className="savedText">{sendMessage}</span>
                        </div>
                        {/*<textarea cols="30" rows="3" onChange={e => setTextAreaText(e.target.value)} value={textAreaText}></textarea>*/}
                        <div className="kdsajjjj-dshjhg-sdlbmdkdploghjd-dddd">
                            <div>
                                <input type="number" onChange={handleChangePriceCharges} value={priceCharges}/> рублей
                            </div>
                            <textarea onChange={handleChange} value={text} />
                            
                        </div>
                    </div>

                    <table className="usersTable">
                        <tr>
                            <th>
                                {/*<input type="checkbox" onChange={handleCheckAll} />*/}
                            </th>
                            <th>#</th>
                            <th>nickname</th>
                            <th>telegram id</th>
                            <th>Рефераллов</th>
                            <th>Баланс</th>
                        </tr>
                        { users.map((user) =>
                            <tr>
                                <td><input type="checkbox" onChange={(event) => handleCheckboxChange(event, user.id)} /></td>
                                <td>{user.id}</td>
                                <td>{user.name}</td>
                                <td>{user.user_id}</td>
                                <td>{user.referals}</td>
                                <td>{user.balance}</td>
                            </tr>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
}

export default Charges;
