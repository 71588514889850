import './App.css';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import React from "react";
import MapStats from "./Pages/MapStats";
import MainPage from "./Pages/MainPage";
import {BrowserRouter, Routes, Route, Router, Link, NavLink } from "react-router-dom";
import MapsStats from "./Pages/MapsStats";
import IndexPage from "./Pages/IndexPage";
import AgentsBotList from "./Pages/AgentsBot/List";
import AgentsBotMore from "./Pages/AgentsBot/More";
import { AiFillAlert, AiFillFund } from "react-icons/ai";
import { BsBuildingFillAdd, BsFillBarChartFill, BsFillPinMapFill } from "react-icons/bs";
import Stats from "./Pages/AgentsBot/Stats";
import Campaign from "./Pages/Campaign";
import Charges from "./Pages/Charges";
import OffersSendMoney from "./Pages/OffersSendMoney";
import OffersSendMoneyCurrent from "./Pages/OffersSendMoneyCurrent";
import Ads from "./Pages/ads";
import AgentsBotChat from "./Pages/AgentsBot/Chats";

function App() {
    const [token, setToken] = React.useState(null);
    const [isAuth, setIsAuth] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [buttonText, setButtonText] = React.useState("Авторизация");
    const [formData, setFormData] = React.useState({
        login: '',
        password: ''
    });

    const handleAuthClick = async () => {
        setButtonText("Загрузка...")
        try {
            // Отправка данных на сервер
            const response = await axios.post('https://api.mr-flip.ru/api/dashboard/auth/user/get', {
                login: formData.login,
                password: formData.password
            });

            console.log('Успешный ответ от сервера:', response.data);

            setErrorMessage("");
            setIsAuth(true);
            localStorage.setItem("dashboard_token", response.data.token);

            setButtonText("Авторизация")
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);

            console.log(error.response.status);
            setErrorMessage(error.response.data.message);

            setButtonText("Попробовать еще раз!")
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    React.useEffect( () => {
        async function checkAuth(){
            if (localStorage.getItem('dashboard_token') !== null) {
                try {
                    // Отправка данных на сервер
                    const response = await axios.post('https://api.mr-flip.ru/api/dashboard/auth/user/check-token', {
                        token: localStorage.getItem('dashboard_token')
                    });

                    console.log('Успешный ответ от сервера:', response.data);

                    setIsAuth(true);
                } catch (error) {
                    console.error('Ошибка при отправке данных:', error);

                    localStorage.removeItem("dashboard_token");
                    setIsAuth(false);
                }
            } else {
                console.log("НЕТ ТОКЕНА");
            }
        }checkAuth();
    }, []);

    return (
        <div>
            {isAuth ? (
                <div className="App">
                    <div className="menu">
                        <div className="menu__list">
                            <NavLink to={"/"} className="menu__list-item desktop"
                                     activeclassname="active">Главная</NavLink>
                            <NavLink to={"/ads"} className="menu__list-item desktop"
                                     activeclassname="active">Объявления сегодня</NavLink>
                            <NavLink to={"/"} className="menu__list-item mobile"
                                     activeclassname="active">{AiFillFund}</NavLink>
                            <NavLink to={"/index"} className="menu__list-item desktop"
                                     activeclassname="active">Индексы</NavLink>
                            <NavLink to={"/index"} className="menu__list-item mobile"
                                     activeclassname="active">{BsFillBarChartFill}</NavLink>
                            <NavLink to={"/maps/stats"} className="menu__list-item desktop"
                                     activeclassname="active">Районы</NavLink>
                            <NavLink to={"/maps/stats"} className="menu__list-item mobile"
                                     activeclassname="active">{BsFillPinMapFill}</NavLink>
                            <NavLink to={"/agentsbot/objects"} className="menu__list-item desktop"
                                     activeclassname="active">Предложения агентов</NavLink>
                            <NavLink to={"/campaign"} className="menu__list-item desktop" activeclassname="active">Рекламная
                                кампания</NavLink>
                            <NavLink to={"/charges"} className="menu__list-item desktop"
                                     activeclassname="active">Начисления по рефераллам</NavLink>
                            <NavLink to={"/offers/send/money"} className="menu__list-item desktop"
                                     activeclassname="active">Начисления по офферам</NavLink>
                            <NavLink to={"/agentsbot/objects"} className="menu__list-item mobile"
                                     activeclassname="active">{BsBuildingFillAdd}</NavLink>
                        </div>
                    </div>
                    <div className="content">
                        <Routes>
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="/maps/stats" element={<MapsStats/>}/>
                            <Route path="/maps/stats/:id/check" element={<MapStats/>}/>
                            <Route path="/agentsbot/objects" element={<AgentsBotList/>}/>
                            <Route path="/agentsbot/objects/:user_id/:object_id" element={<AgentsBotMore/>}/>
                            <Route path="/agentsbot/chats/:user_id/:object_id" element={<AgentsBotChat/>}/>
                            <Route path="/index" element={<IndexPage/>}/>
                            <Route path="/agentsbot/stats/:type" element={<Stats/>}/>
                            <Route path="/campaign" element={<Campaign/>}/>
                            <Route path="/charges" element={<Charges/>}/>
                            <Route path="/offers/send/money" element={<OffersSendMoney/>}/>
                            <Route path="/offers/send/money/:id" element={<OffersSendMoneyCurrent/>}/>
                            <Route path="/ads" element={<Ads/>}/>
                        </Routes>
                    </div>
                </div>
            ) : (
                <div className="auth__overlay">
                    <div className="auth__form">
                        <label htmlFor="login">Логин</label>
                        <input
                            type="text"
                            name="login"
                            value={formData.login}
                            onChange={handleInputChange}
                        />

                        <label htmlFor="password">Пароль</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />

                        <button onClick={handleAuthClick}>{buttonText}</button>
                        <span className="authErrorMessage">{errorMessage}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
