import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import BootstrapTable from "react-bootstrap-table-next";

function Campaign(){
    const [items, setItems] = React.useState(null);
    const [priceSell, setPriceSell] = React.useState(0);
    const [allHumans, setAllHumans] = React.useState(0);
    const [allNewObjects, setAllNewObjects] = React.useState(0);

    const columnsTable = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true
        }, {
            dataField: 'link',
            text: 'Ссылка',
            sort: true
        }, {
            dataField: 'counter',
            text: 'Приведенных',
            sort: true
        }, {
            dataField: 'referals_counter',
            text: 'Приведенные привели рефераллов',
            sort: true
        }, {
            dataField: 'objects_count_from_campagin',
            text: 'Объектов предложили приведенные',
            sort: true
        }, {
            dataField: 'objects_count_from_campagin_referals',
            text: 'Объектов предложили рефераллы приведенных',
            sort: true
        }, {
            dataField: 'input-objects',
            text: 'Суммарно объектов с РК',
            sort: true,
            formatter: sumObjectsFromReferals
        }, {
            dataField: 'input1',
            text: 'Затраты',
            sort: true,
            formatter: sendInput
        }, {
            dataField: 'input2',
            text: 'CAC',
            sort: true,
            formatter: priceOfHuman
        }, {
            dataField: 'input4',
            text: 'CPA',
            sort: true,
            formatter: cpa
        }
    ];

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/campaign/getall");
            let datas = response.data.campaigns;
            let price = 0
            let humans = 0
            let objects = 0

            datas.forEach(e => {
                if(e.id === 30){
                    e.referals_counter = 3;
                    e.counter = 52;
                    e.objects_count_from_campagin = 37;
                    e.objects_count_from_campagin_referals = 1;

                }
                if(e.id !== 20){
                    price = price + e.price;
                    humans = humans + e.counter;
                    objects = objects + (e.objects_count_from_campagin + e.objects_count_from_campagin_referals);
                }
            });

            setPriceSell(price);
            setAllHumans(humans);
            setAllNewObjects(objects);

            console.log(response.data.campaigns);
            setItems(response.data.campaigns);

            // let price = 0

            // items.forEach(e => {
            //     if(e.id !== 20){
            //         price = price + e.price;
            //     }
            // });
        }

        function rePrice(){
            if(items){
                let price = 0

                items.forEach(e => {
                    if(e.id !== 20){
                        price = price + e.price;
                    }
                });

                setPriceSell(price);
            }

        }
        getData();
        // rePrice();
    }, []);


    async function changePrice(cell, row, e){
        let id = row.id;
        let value = e.target.value;
        let data = {
            "id":id,
            "price":value
        }

        let datas = items;
        datas.forEach(e => {
            if(e.id === id){
                e.price = value;
            }
        })
        setItems(null)
        setItems(datas)

        let price = 0

        items.forEach(e => {
            if(e.id !== 20){
                price = price + parseInt(e.price);
            }
        });

        setPriceSell(price);

        const response_1 = await axios.post("https://api.mr-flip.ru/api/dashboard/campaign/set/price", data);
        console.log(response_1)

        const response_2 = await axios.post("https://api.mr-flip.ru/api/dashboard/campaign/getall");
        console.log(response_2.data.campaigns);
        setItems(response_2.data.campaigns);

        // rePrice()
    }

    function sendInput(cell, row){
        return (
            // <span>{row.date.split(" ")[0]}</span>
            <>
                <input type="text" value={row.price} onChange={e => changePrice(cell, row, e)}/>р
            </>
        )
    }

    function priceOfHuman(cell, row) {
        if(row.id === 20){
            return (
                <>-</>
            )
        }else{
            let avalue = row.price / row.counter;
            if(row.counter === 0){
                avalue = 0
            }
            return (
                <>{avalue.toFixed(0)}</>
            )
        }
    }
    function sumObjectsFromReferals(cell, row) {
        return (
            <>{row.objects_count_from_campagin + row.objects_count_from_campagin_referals}</>
        )
    }
    function cpa(cell, row) {
        if((row.objects_count_from_campagin + row.objects_count_from_campagin_referals) == 0 || row.price == 0){
            return (
                <>0</>
            )
        }else {
            return (
                <>{(row.price / (row.objects_count_from_campagin + row.objects_count_from_campagin_referals)).toFixed(0)}</>
            )
        }
    }

    const defaultSorted = [
        {
            dataField: 'id',
            order: 'asc' // Начальная сортировка по возрастанию
        }
    ];

    if(!items) return <Loader/>;

    return (
        <>
            <h1>Список рекламных кампаний</h1>
            <div className="buttons-row">
                <button onClick={(e) => (async () => {
                    try {
                        const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/0/get");

                        setItems(response.data.objects);
                        console.log(response.data.objects);
                    } catch(e){

                    }
                })()}>Одиночные</button>
                <button onClick={(e) => (async () => {
                    try {
                        const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/1/get");

                        setItems(response.data.objects);
                        console.log(response.data.objects);
                    } catch(e){

                    }
                })()}>offer</button>
            </div>
            Сумма общих затрат: {priceSell}
            <BootstrapTable keyField='id' defaultSorted={defaultSorted} data={ items } columns={ columnsTable } />
            <div className="campagins-price-row">
                <span>Сумма общих затрат: {priceSell}</span>
                <span>Итого приведенных: {allHumans}</span>
                <span>Итого CAC: {(priceSell / allHumans).toFixed(0)}</span>
                <span>Итого CPA: {(priceSell / allNewObjects).toFixed(0)}</span>
            </div>
        </>
    );
}

export default Campaign;
