import React from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../../Components/Loader";
import {Link} from "react-router-dom";

const CustomButton = ({ row, onArchive }) => {
    const handleClick = () => {
        // Отправляем POST-запрос на указанный адрес и включаем row.id в данные запроса
        axios.post('https://api.mr-flip.ru/api/v1/agentsbot/objects/toarchive/' + row.id)
            .then(response => {
                // Обработка успешного запроса
                console.log('POST запрос выполнен успешно', response);
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Ошибка при выполнении POST запроса', error);
            });

    };

    return (
        <button onClick={handleClick}>В архив</button>
    );
};

const columns = [
    {
        dataField: 'id',
        text: '#',
        sort: true
    }, {
        dataField: 'link',
        text: 'Ссылка',
        formatter: linkFormat,
        sort: true
    }, {
        dataField: 'address',
        text: 'Адрес',
        formatter: addressFormat,
        sort: true
    }, {
        dataField: 'price',
        text: 'Цена',
        sort: true
    }, {
        dataField: 'status',
        text: 'Статус',
        sort: true,
        formatter: statusText
    },  {
        dataField: 'created_at',
        text: 'Дата',
        sort: true,
        formatter: dateFormat,
        style: { width: '200px' },
    }, {
        dataField: 'none',
        text: '',
        sort: false,
        formatter: objectMoreButton
    }, {
        dataField: 'none',
        text: '',
        sort: false,
        formatter: objectMoreNewButton
    },
    // {
    //     dataField: 'none',
    //     text: '',
    //     sort: false,
    //     formatter: (cell, row) => (
    //         <CustomButton row={row} />
    //     ),
    //     style: { width: '130px' },
    // }
];
function addressFormat(cell, row){
    let address = row.address;
    address = address.replace("город", "");
    address = address.replace("улица", "");
    address = address.replace("Россия,", "");
    address = address.replace("Москва,", "");
    address = address.replace(", ,", ",");
    return (
        <span>{address}</span>
    )
}
function linkFormat(cell, row){
    let link = row.link;
    if(link.length > 45){
        link = link.substring(0, 45);
        return (
            <span>{link}...</span>
        )
    }else{
        return (
            <span>{link}</span>
        )
    }
}
function dateFormat(cell, row){
    if(row.created_at !== null) {
        // Исходная дата в строковом формате
        var dateString = (row.created_at).toString();

        // Разбиение строки на отдельные элементы
        var dateParts = dateString.split(/[- :]/);

        // Создание нового объекта Date с использованием разобранных элементов
        var date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4]);

        // Получение отдельных частей даты и времени
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();

        // Формирование строки в нужном формате
        var formattedDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day) + " в " + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);

        // console.log(row.map_id)
        return (
            <span>{formattedDate}</span>
        )
    }else{
        return (
            <span>-</span>
        )
    }
}
function objectMoreButton(cell, row){
    // console.log(row.map_id)
    return (
        <Link to={"/agentsbot/objects/" + row.agent_id + "/" + row.id}>Подробнее</Link>
    )
}
function objectMoreNewButton(cell, row){
    // console.log(row.map_id)
    return (
        <Link to={"/agentsbot/chats/" + row.agent_id + "/" + row.id}>[NEW] Подробнее</Link>
    )
}
function statusText(cell, row){
    if(row.status === 1){
        return (
            "На рассмотрении"
        )
    }else if(row.status === 2){
        return (
            "Принято"
        )
    }else if(row.status === 3){
        return (
            "Отклонено"
        )
    }
}

function AgentsBotList(){
    const [items, setItems] = React.useState(null);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/1/get");
            setItems(response.data.objects);
            console.log(response.data.objects);
        }
        getData();
    }, []);

    const getItems = (status_id) => {
        // event.preventDefault();

        const response = axios.post("https://api.mr-flip.ru/api/agentsbot/objects/1/get");
        setItems(response.data.objects);
        console.log(response.data.objects);
        console.log("test")
    }

    function objectToArchiveButtonHandler(id){
        console.log(id)
    }

    async function handleClick_1(e){
        e.preventDefault();

        const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/1/get");
        setItems(null);
        setItems(response.data.objects);
        console.log(response.data.objects);
    }
    const handleClick_2 = async (e) => {
        e.preventDefault();

        const response = axios.post("https://api.mr-flip.ru/api/agentsbot/objects/2/get");
        setItems(null);
        setItems(response.data.objects);
        console.log(response.data);
    }
    const handleClick_3 = async (e) => {
        e.preventDefault();

        const response = axios.post("https://api.mr-flip.ru/api/agentsbot/objects/3/get");
        setItems(null);
        setItems(response.data.objects);
        console.log(response.data.objects);
    }

    if(!items) return <Loader />;

    return (
        <>
            <div className="header-row">
                <h1>Предложения агентов</h1>
                <div className="buttons-row">
                    <button onClick={(e) => (async () => {
                        try {
                            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/0/get");

                            setItems(response.data.objects);
                            console.log(response.data.objects);
                        } catch(e){

                        }
                    })()}>Все</button>
                    <button onClick={(e) => (async () => {
                        try {
                            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/1/get");

                            setItems(response.data.objects);
                            console.log(response.data.objects);
                        } catch(e){

                        }
                    })()}>На рассмотрении</button>
                    <button onClick={(e) => (async () => {
                        try {
                            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/2/get");

                            setItems(response.data.objects);
                            console.log(response.data.objects);
                        } catch(e){

                        }
                    })()}>Принято</button>
                    <button onClick={(e) => (async () => {
                        try {
                            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/3/get");

                            setItems(response.data.objects);
                            console.log(response.data.objects);
                        } catch(e){

                        }
                    })()}>Отклонено</button>
                    <button onClick={(e) => (async () => {
                        try {
                            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/999/get");

                            setItems(response.data.objects);
                            console.log(response.data.objects);
                        } catch(e){

                        }
                    })()}>Архив</button>
                </div>
            </div>
            <BootstrapTable keyField='id' data={ items } columns={ columns } classes="objects-table">
            </BootstrapTable>
        </>
    );
}

export default AgentsBotList;
