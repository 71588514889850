import React, { useState, useEffect } from 'react';
import {Link, redirect, useParams} from "react-router-dom";
import axios from "axios";
import Loader from "../Components/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import { Line } from "react-chartjs-2";

const columnsIndexes = [
    {
        dataField: 'date',
        text: 'Дата',
        sort: true
    }, {
        dataField: 'first',
        text: 'Добавлено',
        sort: true
    }, {
        dataField: 'second',
        text: 'Добавлено',
        sort: true
    }
];
const columnsIndexesEveryDay = [
    {
        dataField: 'date',
        text: 'Дата',
        sort: true,
        formatter: dateFormat
    }, {
        dataField: 'one_rooms_count',
        text: 'Кол-во 1комн',
        sort: true
    }, {
        dataField: 'two_rooms_count',
        text: 'Кол-во 2комн',
        sort: true
    }, {
        dataField: 'one_rooms_mean',
        text: '1 комн средняя',
        sort: true
    }, {
        dataField: 'two_rooms_mean',
        text: '2 комн средняя',
        sort: true
    }, {
        dataField: 'one_rooms_median',
        text: '1 комн медиана',
        sort: true
    }, {
        dataField: 'two_rooms_median',
        text: '2 комн медиана',
        sort: true
    }, {
        dataField: 'rooms_count',
        text: 'Общее кол-во',
        sort: true
    }, {
        dataField: 'rooms_median_avg',
        text: 'Общая медиана',
        sort: true
    }, {
        dataField: 'soot_median_avg',
        text: 'Отношение общей медианы к пред. дню',
        sort: true,
        formatter: medianFormat
    }, {
        dataField: 'index_median',
        text: 'Индекс кол-ва',
        sort: true
    }
];
const columnsIndexesEveryWeek = [
    {
        dataField: 'date',
        text: 'Дата',
        sort: true,
        formatter: dateFormat
    }, {
        dataField: 'rooms_count',
        text: 'Общее кол-во',
        sort: true
    }, {
        dataField: 'rooms_median_avg',
        text: 'Общая медиана',
        sort: true
    }, {
        dataField: 'soot_median_avg',
        text: 'Отношение общей медианы к пред. дню',
        sort: true,
        formatter: medianFormat
    }, {
        dataField: 'index_median',
        text: 'Индекс кол-ва',
        sort: true
    }
];

function dateFormat(cell, row){
    return (
        <span>{row.date.split(" ")[0]}</span>
    )
}
function medianFormat(cell, row){
    if(row.soot_median_avg > 0){
        return (
            <span>+{row.soot_median_avg}%</span>
        )
    }else{
        return (
            <span>{row.soot_median_avg}%</span>
        )
    }
}

function IndexPage(){
    const [indexes, setIndexes] = React.useState(null);
    const [indexesEveryDay, setIndexesEveryDay] = React.useState(null);
    const [indexesEveryDayTable, setIndexesEveryDayTable] = React.useState(null);
    const [indexesEveryWeekTable, setIndexesEveryWeekTable] = React.useState(null);
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");

    function sortDataTable(){
        let start = new Date(filterStartDate) / 1000,
            end = new Date(filterEndDate) / 1000;

        start = isNaN(start) ? 0 : start;
        end = isNaN(end) ? 9999999999 : end;

        // console.log(start)
        // console.log(end)

        let array = []

        indexesEveryDay.forEach(e => {
            if(e.date_timestamp >= start && e.date_timestamp <= end){
                array.push(e)
            }
        });

        array = array.sort((item1, item2) => item1["date_timestamp"] > item2["date_timestamp"] ? 1 : -1);
        setIndexesEveryDayTable(array)
    }

    React.useEffect(() => {
        async function getDataIndexes() {
            const response = await axios.post("https://api.mr-flip.ru/api/v1/data/square-list");
            setIndexes(response.data.data);
        }
        async function getDataIndexesEveryDay() {
            const response = await axios.post("https://api.mr-flip.ru/api/indexes/everyday/get");
            // console.log(response.data.indexes)

            let array = [];
            let counterWeek = 1;
            let sumWeekAds = 0;
            let sumWeekMedian = 0;
            let start_date = "";

            let two_rooms_median_weeks = [];
            let two_rooms_median_weeks_labels = [];

            console.log(response.data.indexes)
            response.data.indexes.forEach((e, i) => {
                // считаем по неделям
                // если меньше или равно 2 января, пропускаем
                // понедельник - 3 января
                if(i > 1){
                    if(counterWeek == 1){
                        start_date = e['date'];
                    }

                    sumWeekAds += e['rooms_count'];
                    sumWeekMedian += e['rooms_median_avg'];
                    counterWeek++;

                    if(counterWeek == 8){
                        // console.log(sum / 7)
                        if(i <= 8){
                            two_rooms_median_weeks.push({
                                "date":start_date + " - " + e['date'],
                                "ads":sumWeekAds,
                                "median":sumWeekMedian / 7,
                                "median_old_week":0
                            });
                        }else{
                            two_rooms_median_weeks.push({
                                "date":start_date + " - " + e['date'],
                                "ads":sumWeekAds,
                                "median":sumWeekMedian / 7,
                                "median_old_week":two_rooms_median_weeks[two_rooms_median_weeks.length - 1]['median_old_week']
                            });
                        }
                        sumWeekAds = 0
                        counterWeek = 1
                    }
                }

                setIndexesEveryWeekTable(two_rooms_median_weeks)

                ///////////////////////////////////////////////////

                let soot_median_avg = 0;
                if(i != (response.data.indexes.length - 1)){
                    soot_median_avg = (((e.rooms_median_avg / response.data.indexes[i + 1].rooms_median_avg) * 100) - 100).toFixed(2)
                }

                let temp_array_indexes = [];
                response.data.indexes.forEach(e => {
                    temp_array_indexes.push(e.one_rooms_count + e.two_rooms_count);
                });
                temp_array_indexes = temp_array_indexes.slice(temp_array_indexes.length - 181);
                temp_array_indexes.sort((a, b) => a - b);

                let index_median = 0;
                if((((((e.one_rooms_count + e.two_rooms_count) / temp_array_indexes[91]) * 100) - 100)).toFixed(2) < 0){
                    index_median = (100 + (((((e.one_rooms_count + e.two_rooms_count) / temp_array_indexes[91]) * 100) - 100))).toFixed(0)
                }else{
                    index_median = (((((((e.one_rooms_count + e.two_rooms_count) / temp_array_indexes[91]) * 100) - 100)))).toFixed(0)
                }

                array.push(e);
                array[i]['rooms_count'] = e.one_rooms_count + e.two_rooms_count;
                array[i]['soot_median_avg'] = soot_median_avg;
                array[i]['index_median'] = index_median;
            });

            console.log(setIndexesEveryWeekTable)

            setIndexesEveryDay(array);
            array = array.sort((item1, item2) => item1["date_timestamp"] > item2["date_timestamp"] ? 1 : -1);
            setIndexesEveryDayTable(array);
        }
        getDataIndexes();
        getDataIndexesEveryDay();
    }, []);



    if(!indexes || !indexesEveryDayTable) return <Loader />;

    const everyDayChartData = {
        labels: indexesEveryDayTable.map(item => item.date.split(" ")[0]),
        datasets: [
            {
                label: "1комн. средняя",
                data: indexesEveryDayTable.map(item => item.one_rooms_mean),
                // backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            },
            {
                label: "1комн. медиана",
                data: indexesEveryDayTable.map(item => item.one_rooms_median),
                // backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgb(135,75,192)"
            },
            {
                label: "2комн. средняя",
                data: indexesEveryDayTable.map(item => item.two_rooms_mean),
                // backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgb(75,192,192)"
            },
            {
                label: "2комн. медиана",
                data: indexesEveryDayTable.map(item => item.two_rooms_median),
                // backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgb(135,75,192)"
            }
        ]
    };
    let lineConfig = {
        maintainAspectRatio: false
    };
    return (
        <>
            <div className="indexes-header-row">
                <div>
                    <input type="date" placeholder="2022-12-01" onChange={(e) => setFilterStartDate(e.target.value)} />
                    <input type="date" placeholder="01-01-2023" onChange={(e) => setFilterEndDate(e.target.value)} />
                    <button id="sortTable" onClick={sortDataTable}>Применить</button>
                </div>
                <div className="background-ranges-median-rooms-price">
                    <span className="range_medians_rooms_price">Разница 1комн.медиана: {((indexesEveryDayTable[indexesEveryDayTable.length - 1]['one_rooms_median'] / indexesEveryDayTable[0]['one_rooms_median'] - 1) * 100).toFixed(2)}%</span>
                    <span className="range_medians_rooms_price">Разница 2комн.медиана: {((indexesEveryDayTable[indexesEveryDayTable.length - 1]['two_rooms_median'] / indexesEveryDayTable[0]['two_rooms_median'] - 1) * 100).toFixed(2)}%</span>
                </div>
            </div>

            <div className="row-chart">
                <Line data={everyDayChartData} options={lineConfig} />
            </div>
            <div className="indexes-flex">
                <div className="indexes-flex-item">
                    <h2>Ежедневные индексы</h2>
                    <BootstrapTable keyField='map_id' data={ indexesEveryDayTable } columns={ columnsIndexesEveryDay } />
                </div>
                <div className="indexes-flex-item">
                    <h2>Индексы</h2>
                    <BootstrapTable keyField='map_id' data={ indexes } columns={ columnsIndexes } />
                    <h2>Индексы по неделям</h2>
                    <BootstrapTable keyField='map_id' data={ indexesEveryDayTable } columns={ columnsIndexesEveryWeek } />
                </div>
            </div>
        </>
    );
}

export default IndexPage;
