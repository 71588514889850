import React from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../../Components/Loader";
import {Link, useParams} from "react-router-dom";
import {Line} from "react-chartjs-2";

const columns = [
    {
        dataField: 'currentdate',
        text: 'Дата',
        sort: true
    }, {
        dataField: 'counter',
        text: 'Действий',
        sort: true
    }
];

function Stats(){
    const {type} = useParams();

    const [items, setItems] = React.useState(null);
    const [dates, setDates] = React.useState(null);
    const [counters, setCounters] = React.useState(null);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/current/" + type);
            setItems(response.data.data);
            console.log(response.data.data);

            let tempitems = response.data.data;
            let array_dates = [];
            let array_counters = [];
            tempitems.forEach(e => {
                array_dates.push(e.currentdate);
                array_counters.push(e.counter);
            });
            setDates(array_dates);
            setCounters(array_counters);
        }
        getData();
    }, []);

    const data = {
        labels: dates,
        datasets: [
            {
                label: "Добавлено",
                data: counters,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    };

    let lineConfig = {
        maintainAspectRatio: false
    };

    if(!items) return <Loader />;

    return (
        <>
            <div className="row-chart">
                <Line data={data} options={lineConfig} />
            </div>
            <BootstrapTable keyField='id' data={ items } columns={ columns }>
            </BootstrapTable>
        </>
    );
}

export default Stats;
