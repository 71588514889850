import React from "react";
import {Link, redirect, Route, Routes, useParams} from "react-router-dom";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../Components/Loader";

const columns = [
    {
        dataField: 'area',
        text: 'Район',
        sort: true
    }, {
        dataField: 'views',
        text: 'Просмотры',
        sort: true
    }, {
        dataField: 'ads',
        text: 'Объявления',
        sort: true
    }, {
        dataField: 'button-graph',
        text: 'График',
        formatter: buttonGraph
    }
];

function buttonGraph(cell, row){
    // console.log(row.map_id)
    return (
        <Link to={"/maps/stats/" + row.map_id + "/check"}>График</Link>
    )
}

function MapsStats(){
    const [items, setItems] = React.useState(null);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/get-avg-area-views");
            setItems(response.data.data);
            console.log(response.data.data);
        }
        getData();
    }, []);


    if(!items) return <Loader />;

    return (
        <>
            <h1>Таблица</h1>
            {/*<BootstrapTable keyField='id' data={ items } columns={ columns } />*/}
            <BootstrapTable keyField='map_id' data={ items } columns={ columns }>
            </BootstrapTable>
        </>
    );
}

export default MapsStats;
