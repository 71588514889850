import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import {Link, NavLink, useParams} from "react-router-dom";



function OffersSendMoney(){
    const {id} = useParams();

    const [sendOfferPriceMessage, setSendOfferPriceMessage] = React.useState(null);
    const [saveText, setSaveText] = React.useState(null);
    const [investObject, setInvestObject] = React.useState(null);
    const [days, setDays] = React.useState(null);
    const [sellPrice, setSellPrice] = React.useState(null);
    const [buyPrice, setBuyPrice] = React.useState(null);
    const [dateBuyFact, setDateBuyFact] = React.useState(null);
    const [dateSellFact, setDateSellFact] = React.useState(null);
    const [komPartner, setKomPartner] = React.useState(null);
    const [komPartnerReferals, setKomPartnerReferals] = React.useState(null);

    React.useEffect(() => {
        async function getInvestObjects() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/get/invest/current/object/" + id);
            console.log(response.data.item);
            setInvestObject(response.data.item);

            setDays(response.data.item.sell_period)
            setSellPrice(response.data.item.sell_price_fact)
            setBuyPrice(response.data.item.buy_price_fact)
            setDateBuyFact(response.data.item.buy_date_fact)
            setDateSellFact(response.data.item.sell_date_fact)
            setKomPartner(response.data.item.kom_partner)
            setKomPartnerReferals(response.data.item.kom_partner_referals)
        }
        getInvestObjects();
    }, []);

    // async function sendMoneyOffer(id){
    //     const response = await axios.post("https://api.mr-flip.ru/api/dashboard/send/invest/objects/" + id);
    //     console.log(response.data);
    //
    //     setSendOfferPriceMessage("Деньги начислены " + response.data.userscount + " пользователям")
    //
    //     setTimeout(() => {
    //         setSendOfferPriceMessage("")
    //     }, 3500);
    //
    //     console.log("ok")
    // }


    async function sendMoneyOffer(itemid){
        let jsondata = {
            "sell_price_fact": sellPrice,
            "buy_price_fact":buyPrice,
            "dateBuyFact":dateBuyFact,
            "dateSellFact":dateSellFact,
            "komPartner":komPartner,
            "komPartnerReferals":komPartnerReferals,
            "days":parseInt(((new Date(dateSellFact) - new Date(dateBuyFact)) / (60 * 60 * 24 * 1000))),
            "id":id
        };
        const response_1 = await axios.post("https://api.mr-flip.ru/api/dashboard/get/invest/current/save/object", jsondata);

        console.log(response_1.data);

        setSaveText("Сохранено")

        setTimeout(() => {
            setSaveText("")
        }, 3500);

        console.log("ok")

        const response_2 = await axios.get("https://www.agents-bot.mrflip.ru/api/accrual/sell/"+ itemid +"/");
        console.log(response_2.data);

        // setSendOfferPriceMessage("Деньги начислены " + response_2.data.userscount + " пользователям")

        setTimeout(() => {
            setSendOfferPriceMessage("")
        }, 3500);

        console.log("ok")
    }
    async function sendMoneyOfferTwo(itemid){
        let jsondata = {
            "buy_price_fact":buyPrice,
            "dateBuyFact":dateBuyFact,
            "komPartner":komPartner,
            "komPartnerReferals":komPartnerReferals,
            "id":id
        };
        const response_1 = await axios.post("https://api.mr-flip.ru/api/dashboard/get/invest/current/save_buy/object", jsondata);

        console.log(response_1.data);

        setSaveText("Сохранено")

        setTimeout(() => {
            setSaveText("")
        }, 3500);

        console.log("ok")

        const response_2 = await axios.get("https://www.agents-bot.mrflip.ru/api/accrual/buy/"+ itemid +"/");
        console.log(response_2.data);

        // setSendOfferPriceMessage("Деньги начислены " + response_2.data.userscount + " пользователям")

        setTimeout(() => {
            setSendOfferPriceMessage("")
        }, 3500);

        console.log("ok")
    }
    async function save(){
        let jsondata = {
            "sell_price_fact": sellPrice,
            "buy_price_fact":buyPrice,
            "days":parseInt(((new Date(dateSellFact) - new Date(dateBuyFact)) / (60 * 60 * 24 * 1000))),
            "id":id
        };
        const response = await axios.post("https://api.mr-flip.ru/api/dashboard/get/invest/current/save/object", jsondata);
        console.log(response.data);

        setSaveText("Сохранено")

        setTimeout(() => {
            setSaveText("")
        }, 3500);

        console.log("ok")
    }

    if(investObject === null) return <Loader />;

    return (
        <>
            <h1>Оффер #{investObject.offer_id}</h1>

            <div className="offer-send-money-table-row">
                <div className="object-table charges-table offer-send-money-table">
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            #
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.offer_id}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата покупки
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.offer_date}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата продажи
                        </div>
                        <div className="object-table-row-cell">

                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дней продажи
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.sell_period}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Цена покупки
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.price_buy}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Цена продажи
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.price_sell}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Профит
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.price_sell - investObject.price_buy}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Годовых
                        </div>
                        <div className="object-table-row-cell">
                            {(((investObject.price_sell / investObject.price_buy)- 1) * 100 / days * 365).toFixed(2)}%
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Площадь м²
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.total_area}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата создания
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.created_at}
                        </div>
                    </div>
                </div>
                <div className="object-table charges-table offer-send-money-table">
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Продажа
                        </div>
                        <div className="object-table-row-cell">
                            # {investObject.offer_id}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата покупки
                        </div>
                        <div className="object-table-row-cell">
                            <input type="date" value={dateBuyFact} onChange={e => setDateBuyFact(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата продажи
                        </div>
                        <div className="object-table-row-cell">
                            <input type="date" value={dateSellFact} onChange={e => setDateSellFact(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дней продажи
                        </div>
                        <div className="object-table-row-cell">
                            { (new Date(dateSellFact) - new Date(dateBuyFact)) / (60 * 60 * 24 * 1000) }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Цена покупки
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={buyPrice} onChange={e => setBuyPrice(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Цена продажи
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={sellPrice} onChange={e => setSellPrice(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Профит
                        </div>
                        <div className="object-table-row-cell">
                            {sellPrice - buyPrice}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Годовых
                        </div>
                        <div className="object-table-row-cell">
                            {
                                (((sellPrice / buyPrice)- 1) * 100 / parseInt(((new Date(dateSellFact) - new Date(dateBuyFact)) / (60 * 60 * 24 * 1000))) * 365).toFixed(2)
                            }

                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Комиссия партнеру в рублях
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={komPartner} onChange={e => setKomPartner(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Распределение по реферальной системе %
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={komPartnerReferals} onChange={e => setKomPartnerReferals(e.target.value)}/>%
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Распределение по реферальной системе в рублях
                        </div>
                        <div className="object-table-row-cell">
                            { (sellPrice - buyPrice - komPartner) / 100 * komPartnerReferals }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Было начисление
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.accrual}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата создания
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.created_at}
                        </div>
                    </div>
                    {/*<div className="object-table-row">*/}
                    {/*    <div className="object-table-row-cell">*/}
                    {/*        <span className="savedText">{saveText}</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="object-table-row-cell">*/}
                    {/*        <button onClick={(event) => save()}>Сохранить</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            <span className="savedText">{sendOfferPriceMessage}</span>
                        </div>
                        <div className="object-table-row-cell">
                            <button onClick={(event) => sendMoneyOffer(investObject.offer_id)}>Начислить участникам сети</button>
                        </div>
                    </div>
                </div>
                <div className="object-table charges-table offer-send-money-table">
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Покупка
                        </div>
                        <div className="object-table-row-cell">
                            # {investObject.offer_id}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата покупки
                        </div>
                        <div className="object-table-row-cell">
                            <input type="date" value={dateBuyFact} onChange={e => setDateBuyFact(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Цена покупки
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={buyPrice} onChange={e => setBuyPrice(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Комиссия партнеру в рублях
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={komPartner} onChange={e => setKomPartner(e.target.value)}/>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Распределение по реферальной системе %
                        </div>
                        <div className="object-table-row-cell">
                            <input type="text" value={komPartnerReferals} onChange={e => setKomPartnerReferals(e.target.value)}/>%
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Распределение по реферальной системе в рублях
                        </div>
                        <div className="object-table-row-cell">
                            { komPartner / 100 * komPartnerReferals }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Было начисление
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.accrual}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Дата создания
                        </div>
                        <div className="object-table-row-cell">
                            {investObject.created_at}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            <span className="savedText">{sendOfferPriceMessage}</span>
                        </div>
                        <div className="object-table-row-cell">
                            <button onClick={(event) => sendMoneyOfferTwo(investObject.offer_id)}>Начислить участникам сети</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OffersSendMoney;
