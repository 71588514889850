import React from "react";
import {Link, redirect, useParams} from "react-router-dom";
import axios from "axios";
import Loader from "../Components/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import { Line } from "react-chartjs-2";

function dateFormat(cell, row){
    return (
        <span>{row.date.split(" ")[0]}</span>
    )
}

const columns = [
    {
        dataField: 'date',
        text: 'Дата',
        sort: true,
        formatter: dateFormat
    }, {
        dataField: 'counter',
        text: 'Добавлено',
        sort: true
    }
];

function MapStats(){
    const {id} = useParams();

    const [items, setItems] = React.useState(null);
    const [count, setCount] = React.useState(null);
    const [dates, setDates] = React.useState(null);
    const [filterStartDate, setFilterStartDate] = React.useState(null);
    const [filterEndDate, setFilterEndDate] = React.useState(null);

    const [startIteration, setStartIteration] = React.useState(0);
    const [endIteration, setEndIteration] = React.useState(0);

    function sortDataTable(){
        console.log(startIteration)
        console.log(endIteration)

        if(filterStartDate !== null){
            dates.forEach((e, i) => {
                if(!e.indexOf(filterStartDate)){
                    setStartIteration(i);
                }
            });
        }

        if(filterEndDate !== null){
            dates.forEach((e, i) => {
                if(!e.indexOf(filterEndDate)){
                    setEndIteration(i);
                }
            });
        }
    }

    const data = {
        labels: dates,
        datasets: [
            {
                label: "Добавлено",
                data: count,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    };

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/get-avg-area-views/" + id);

            // console.log(response.data.data);

            let count_array = [];
            let date_array = [];

            response.data.data.forEach(e => {
                count_array.push(e.counter);
                date_array.push(e.date.split(" ")[0]);
            });

            setCount(count_array);
            setDates(date_array);

            setItems(response.data.data);
            setEndIteration(response.data.data.length)
        }
        getData();
    }, []);


    if(!items) return <Loader />;

    return (
        <>
            <div>
                <input type="date" placeholder="01-01-2022" onChange={(e) => setFilterStartDate(e.target.value)} />
                <input type="date" placeholder="01-01-2023" onChange={(e) => setFilterEndDate(e.target.value)} />
                <button id="sortTable" onClick={sortDataTable}>Применить</button>
            </div>
            <Line data={data} />
            <h1>Таблица</h1>
            {/*<BootstrapTable keyField='id' data={ items } columns={ columns } />*/}
            <BootstrapTable keyField='map_id' data={ items } columns={ columns }>
            </BootstrapTable>
        </>
    );
}

export default MapStats;
