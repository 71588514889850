import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import {Link} from "react-router-dom";

function MainPage(){
    const [stats, setStats] = React.useState(null);
    const [users, setUsers] = React.useState(null);
    const [referals, setReferals] = React.useState(null);
    const [uniqAction, setUniqAction] = React.useState(null);
    const [objects, setObjects] = React.useState(null);
    const [filterStartDate, setFilterStartDate] = React.useState(null);
    const [filterEndDate, setFilterEndDate] = React.useState(null);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/all");
            console.log(response.data.stats)
            setStats(response.data.stats);
            setUsers(response.data.users);
            setReferals(response.data.referals);
            setUniqAction(response.data.uniqAction);
            setObjects(response.data.objects);
            console.log(response.data.uniqAction);
        }
        getData();
    }, []);

    async function filterYesterday(){
        const yesterday = new Date(Date.now() - 86400000); // вычитаем 86400000 миллисекунд, что эквивалентно 24 часам
        const year = yesterday.getFullYear();
        const month = (yesterday.getMonth() + 1).toString().padStart(2, "0");
        const day = yesterday.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        console.log(formattedDate);

        setStats(null);

        const data = {
            "startdate": formattedDate,
            "enddate": formattedDate
        }

        const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/all", data);

        console.log(response)
        setStats(response.data.stats);
        setUsers(response.data.users);
        setReferals(response.data.referals);
        setUniqAction(response.data.uniqAction);
        setObjects(response.data.objects);
    }
    async function filterToday(){
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const day = today.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        console.log(formattedDate);

        setStats(null);

        const data = {
            "startdate": formattedDate,
            "enddate": formattedDate
        }

        const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/all", data);

        console.log(response)
        setStats(response.data.stats);
        setUsers(response.data.users);
        setReferals(response.data.referals);
        setUniqAction(response.data.uniqAction);
        setObjects(response.data.objects);
    }

    async function filter(){
        if(filterStartDate !== null && filterEndDate !== null){
            console.log(filterStartDate)
            console.log(filterEndDate)
            setStats(null);

            const data = {
                "startdate": filterStartDate,
                "enddate": filterEndDate
            }

            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/all", data);

            console.log(response)
            setStats(response.data.stats);
            setUsers(response.data.users);
            setReferals(response.data.referals);
            setUniqAction(response.data.uniqAction);
            setObjects(response.data.objects);
        }else if (filterStartDate !== null){
            console.log(filterStartDate)

            setStats(null);

            const data = {
                "startdate": filterStartDate
            }

            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/all", data);

            console.log(response)
            setStats(response.data.stats);
            setUsers(response.data.users);
            setReferals(response.data.referals);
            setUniqAction(response.data.uniqAction);
            setObjects(response.data.objects);
        }else if (filterEndDate !== null){
            console.log(filterEndDate)

            setStats(null);

            const data = {
                "enddate": filterEndDate
            }

            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/agentsbot/stats/all", data);

            console.log(response)
            setStats(response.data.stats);
            setUsers(response.data.users);
            setReferals(response.data.referals);
            setUniqAction(response.data.uniqAction);
            setObjects(response.data.objects);
        }else{
            console.log("пусто")
        }
    }

    if(!stats) return <Loader />;

    return (
        <>
            <div className="indexes-header-row">
                <div class="mainPage-filter">
                    <input type="date" placeholder="2022-12-01" value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                    <input type="date" placeholder="01-01-2023" value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                    <button id="sortTable" onClick={filter}>Применить</button>
                    <button id="sortTable" onClick={filterToday}>Сегодня</button>
                    <button id="sortTable" onClick={filterYesterday}>Вчера</button>
                </div>
            </div>

            <div className="main-row">
                <Link to={"/agentsbot/stats/-1"} className="main-row-item">
                    <span className="main-row-item-h1">Пользователей</span>
                    <span className="main-row-item-h2">{users}</span>
                </Link>
                <Link to={"/agentsbot/stats/-2"} className="main-row-item">
                    <span className="main-row-item-h1">Активных пользователей сегодня</span>
                    <span className="main-row-item-h2">{uniqAction[0]['uniqExact(telegram_user_id)']}</span>
                </Link>
                <a className="main-row-item">
                    <span className="main-row-item-h1">Рефераллов привели</span>
                    <span className="main-row-item-h2">{referals}</span>
                </a>
                <Link to={"/agentsbot/stats/-4"} className="main-row-item">
                    <span className="main-row-item-h1">Объектов предложили</span>
                    <span className="main-row-item-h2">{objects}</span>
                </Link>
                {stats.map((stat) =>
                    <Link to={"/agentsbot/stats/" + stat.type} className="main-row-item">
                        <span className="main-row-item-h1">{stat.type_string}</span>
                        <span className="main-row-item-h2">{stat.count}</span>
                    </Link>
                )}
            </div>
        </>
    );
}

export default MainPage;
