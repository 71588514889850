import React from "react";
import axios from "axios";
import Loader from "../Components/Loader";
import {Link, NavLink} from "react-router-dom";



function OffersSendMoney(){
    const [sendOfferPriceMessage, setSendOfferPriceMessage] = React.useState(null);
    const [investObjects, setInvestObjects] = React.useState(null);

    React.useEffect(() => {
        async function getInvestObjects() {
            const response = await axios.post("https://api.mr-flip.ru/api/dashboard/get/invest/objects");
            console.log(response.data.items);
            setInvestObjects(response.data.items);
        }
        getInvestObjects();
    }, []);

    async function sendMoneyOffer(id){
        const response = await axios.post("https://api.mr-flip.ru/api/dashboard/send/invest/objects/" + id);
        console.log(response.data);

        setSendOfferPriceMessage("Деньги начислены " + response.data.userscount + " пользователям")

        setTimeout(() => {
            setSendOfferPriceMessage("")
        }, 3500);

        console.log("ok")
    }

    if(investObjects === null) return <Loader />;

    return (
        <>
            <h1>Начислить 5% от профита всем участникам</h1>
            <table className="usersTable">
                <tr>
                    <th>#</th>
                    <th>Цена покупки</th>
                    <th>Цена продажи</th>
                    <th>Профит</th>
                    <th>Годовых</th>
                    <th>м²</th>
                    <th>Дата</th>
                    <th></th>
                </tr>
                <span className="savedText">{sendOfferPriceMessage}</span>
                { investObjects.map((item) =>
                    <tr>
                        <td>{item.offer_id}</td>
                        <td>{item.price_buy}</td>
                        <td>{item.price_sell}</td>
                        <td>{(item.price_sell - item.price_buy).toFixed(0)}</td>
                        <td>{item.margin_annual_interest}</td>
                        <td>{item.total_area}</td>
                        <td>{item.created_at}</td>
                        {/*<td><button onClick={(event) => sendMoneyOffer(item.id)}>Начислить</button></td>*/}
                        {/*<td><button onClick={(event) => sendMoneyOffer(item.id)}>Подробнее</button></td>*/}
                        <td><NavLink to={"/offers/send/money/" + item.id}>Подробнее</NavLink></td>
                    </tr>
                )}
            </table>
        </>
    );
}

export default OffersSendMoney;
