import React, {useRef} from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../../Components/Loader";
import {Link, NavLink, useParams} from "react-router-dom";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { BsFillChatFill } from "react-icons/bs";
import pusher from "../../pusher";

function AgentsBotChat(){
    const {user_id, object_id} = useParams();

    const [item, setItem] = React.useState(null);
    const [agent, setAgent] = React.useState(null);
    const [messages, setMessages] = React.useState([]);
    const [messageText, setMessageText] = React.useState("");
    const chatRef = useRef(null);

    const [chats, setChats] = React.useState(null);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/chats/chats/get");

            setChats(response.data.objects);
        }
        getData();

        const intervalId = setInterval(getData, 3000);

        // Функция очистки интервала
        return () => clearInterval(intervalId);
    }, []);

    React.useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    React.useEffect(() => {
        async function getMessages() {
            setMessages(null);

            const response = await axios.get("https://www.agents-bot.mrflip.ru/chats/get_chat_history/"+ object_id +"/-1/");

            setMessages(response.data);

            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
        getMessages();

        const channel = pusher.subscribe('property-' + object_id);
        channel.bind('message', socketMessage => {
            console.log('Received event:', socketMessage.text);

            if(socketMessage.from_user_id != -1){
                const currentDate = new Date().toISOString();

                console.log(messages)

                setMessages(prevMessages => [...prevMessages, {
                    "created_at":currentDate,
                    "from_user_id": socketMessage.from_user_id,
                    "id":0,
                    "is_read":true,
                    "property_id":object_id,
                    "text":socketMessage.text,
                    "to_user_id":-1
                }]);

                axios.post("https://api.mr-flip.ru/api/agentsbot/objects/chats/set_is_read/" + socketMessage.from_user_id + "/" + object_id);

                console.log("Добавили новое сообщение в массив");
            }

        });

        return () => {
            pusher.unsubscribe('property-' + object_id);
        };
    }, [user_id, object_id]);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/get/" + object_id);
            console.log(response.data)
            setItem(response.data.object);
            setAgent(response.data.agent);

            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        }
        getData();
    }, []);

    async function sendMessageFromChat(e){
        e.preventDefault();

        const currentDate = new Date().toISOString();
        setMessages([...messages, {
            "created_at":currentDate,
            "from_user_id": -1,
            "id":0,
            "is_read":false,
            "property_id":object_id,
            "text":messageText,
            "to_user_id":user_id
        }])

        const data = {
            "text": messageText,
            "channel": "property-" + object_id,
            "from_user_id": -1,
            "to_user_id": user_id
        }
        setMessageText("");

        const response = await axios.post("https://www.agents-bot.mrflip.ru/chats/send_message/", data);

        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    function formatDateMessage(dateString) {
        const date = new Date(dateString);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);

        // Функция для форматирования даты "день месяца"
        const dayMonthFormat = (date, includeYear = false) => {
            const options = includeYear
                ? { month: 'long', day: 'numeric', year: 'numeric' }
                : { month: 'long', day: 'numeric' };
            return date.toLocaleDateString('ru-RU', options);
        };

        // Функция для форматирования времени "часы:минуты"
        const timeFormat = (date) => {
            const options = { hour: '2-digit', minute: '2-digit' };
            return date.toLocaleTimeString('ru-RU', options);
        };

        // Проверка, сегодня ли это
        if (date.toDateString() === now.toDateString()) {
            return `Сегодня в ${timeFormat(date)}`;
        }

        // Проверка, вчера ли это
        if (date.toDateString() === yesterday.toDateString()) {
            return `Вчера в ${timeFormat(date)}`;
        }

        // Проверка на текущий год и форматирование соответственно
        const currentYear = now.getFullYear();
        const messageYear = date.getFullYear();

        if (currentYear !== messageYear) {
            // Если дата не в текущем году, включаем год в вывод
            return `${dayMonthFormat(date, true)} в ${timeFormat(date)}`;
        }

        // Иначе возвращаем "день месяца в часы:минуты"
        return `${dayMonthFormat(date)} в ${timeFormat(date)}`;
    }
    function dateFormat(date){
        if(date !== null) {
            // Исходная дата в строковом формате
            date = date.split(".")[0]
            date = date.replace("T", "")
            var dateString = (date).toString();

            // Разбиение строки на отдельные элементы
            var dateParts = dateString.split(/[- :]/);

            // Создание нового объекта Date с использованием разобранных элементов
            var date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4]);

            // Получение отдельных частей даты и времени
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hours = date.getHours();
            var minutes = date.getMinutes();

            // Формирование строки в нужном формате
            var formattedDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day) + " в " + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);

            // console.log(row.map_id)
            return formattedDate;
        }else{
            return "-";
        }
    }
    function textFormatMessageList(text){
        if(text.length > 45){
            text = text.substring(0, 100);
            return text + "..."
        }else{
            return text;
        }
    }

    function addressFormat(address){
        address = address.replace("город", "");
        address = address.replace("г ", "");
        address = address.replace("улица", "");
        address = address.replace("Россия,", "");
        address = address.replace("Москва,", "");
        address = address.replace(", ,", ",");
        return (
            <span>{address}</span>
        )
    }

    if(!chats || !item || !agent) return <Loader />;

    return (
        <>
            <div className="chats-page">
                <div className="chats-page_list">
                    {chats.map((chat) =>
                        <NavLink to={"/agentsbot/chats/" + chat.agent_id + "/" + chat.id} className="chats-page_list-item" activeclassname="active">
                            <div className="chats-page_list-item-header">
                                <span className="chats-page_list-item-address">{addressFormat(chat.address)}</span>
                                <span className="chats-page_list-item-created">{formatDateMessage(chat.created_at)}</span>
                            </div>
                            <div className="chats-page_list-item-body">
                                <span className="chats-page_list-item-message">
                                    {chat.from_user_id !== -1 ? textFormatMessageList(chat.text) : "Вы: " + textFormatMessageList(chat.text)}
                                </span>
                                {parseInt(chat.id) !== parseInt(object_id) ?
                                    chat.is_read === false && chat.from_user_id !== -1 ? <div className="new_message_ping"></div> : ""
                                :
                                    ""
                                }
                            </div>

                        </NavLink>
                    )}
                </div>
                <div className="chats-page_chat">
                    {messages ?
                        <>
                            <div className="chats-page_chat-messages" ref={chatRef}>
                                {messages.map((message) =>
                                    <>
                                        {message.from_user_id === -1 ?
                                            <div className="chats-page_chat-messages-item admin">
                                                <div className="chats-page_chat-messages-item-text">
                                                    <span className="chats-page_chat-messages-item-text-text">
                                                        {message.text}
                                                    </span>
                                                    <span className="chats-page_chat-messages-item-text-time">
                                                        {formatDateMessage(message.created_at)}
                                                    </span>
                                                </div>
                                            </div>
                                            :
                                            <div className="chats-page_chat-messages-item user">
                                                <div className="chats-page_chat-messages-item-text">
                                                    <span className="chats-page_chat-messages-item-text-text">
                                                        {message.text}
                                                    </span>
                                                    <span className="chats-page_chat-messages-item-text-time">
                                                        {formatDateMessage(message.created_at)}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                )}
                            </div>
                            <form onSubmit={sendMessageFromChat} className="chats-page_chat-form">
                                <input type="text" className="chats-page_chat-form-input" value={messageText} onChange={(e) => setMessageText(e.target.value)} />
                                <button className="chats-page_chat-form-submit">
                                    <img src="https://telegram-bot-web-app.mrflip.ru/static/media/send-icon.8aeb0c265770f36142656f249963197a.svg" alt=""/>
                                </button>
                            </form>
                        </>
                        :
                        <Loader />
                    }
                </div>
                <div className="chats-page_info">
                    Информация по объекту:
                    <fieldset>
                        <legend>Агент</legend>
                        <span className="chats-page_info-row">Телеграм ID: <span className="chats-page_info-row-value">{item.agent_id}</span></span>
                        <span className="chats-page_info-row">Имя: <span className="chats-page_info-row-value">{agent.first_name} {agent.last_name}</span></span>
                        <span className="chats-page_info-row">Баланс: <span className="chats-page_info-row-value">{agent.balance}</span></span>
                        <span className="chats-page_info-row">Дата регистрации: <span className="chats-page_info-row-value">{formatDateMessage(agent.created_at)}</span></span>
                    </fieldset>
                    <fieldset>
                        <legend>Объект</legend>
                        {item.link ? <span className="chats-page_info-row">Ссылка:<span className="chats-page_info-row-value"><a href={item.link} target="_blank">Перейти</a></span></span> : <></>}
                        {item.address ? <span className="chats-page_info-row">Адрес: <span className="chats-page_info-row-value">{item.address}</span></span> : <></>}
                        {item.rooms_count ? <span className="chats-page_info-row">Комнат: <span className="chats-page_info-row-value">{item.rooms_count}</span></span> : <></>}
                        {item.total_area ? <span className="chats-page_info-row">Площадь: <span className="chats-page_info-row-value">{item.total_area}</span></span> : <></>}
                        {item.price ? <span className="chats-page_info-row">Цена агента: <span className="chats-page_info-row-value">{item.price}</span></span> : <></>}
                        {item.price_offer ? <span className="chats-page_info-row">Цена наша: <span className="chats-page_info-row-value">{item.price_offer}</span></span> : <></>}
                        {item.price_predict ? <span className="chats-page_info-row">Цена предсказания: <span className="chats-page_info-row-value">{item.price_predict}</span></span> : <></>}
                        {item.created_at ? <span className="chats-page_info-row">Дата создания: <span className="chats-page_info-row-value">{formatDateMessage(item.created_at)}</span></span> : <></>}
                    </fieldset>
                </div>
            </div>
        </>
    );
}

export default AgentsBotChat;
